<template>
  <UDashboardSlideover
    v-model="isStatsCardSlideoverOpen"
    title="Your weekly stats"
  >
    <BaseLoader v-if="loading">Loading stats...</BaseLoader>

    <div
      v-if="stats"
      class="space-y-6"
    >
      <UCard>
        <template #header>
          <h3 class="text-base font-semibold leading-6">
            Today's Outreach Events
          </h3>
        </template>
        <p class="mt-2 text-2xl font-bold">{{ stats.today }} events today</p>
        <p class="mt-2 flex items-baseline gap-x-2">
          <span
            class="text-sm font-semibold"
            :class="
              stats.todayVsYesterday > 0 ? 'text-green-600' : 'text-red-600'
            "
          >
            {{ stats.todayVsYesterday > 0 ? "+" : ""
            }}{{ stats.todayVsYesterday }}
          </span>
          <span class="text-sm">vs yesterday</span>
        </p>
      </UCard>

      <UCard>
        <template #header>
          <h3 class="text-base font-semibold leading-6">
            This Week's Average (Sat-Fri)
          </h3>
        </template>
        <p class="mt-2 text-2xl font-bold">
          {{ stats.thisWeekAvg.toFixed(2) }} events/day
        </p>
        <p class="mt-2 flex items-baseline gap-x-2">
          <span
            class="text-sm font-semibold"
            :class="
              stats.weeklyPerformance > 0 ? 'text-green-600' : 'text-red-600'
            "
          >
            {{ stats.weeklyPerformance > 0 ? "+" : ""
            }}{{ stats.weeklyPerformance.toFixed(2) }}%
          </span>
          <span class="text-sm">
            vs last week ({{ stats.lastWeekAvg.toFixed(2) }} events/day)
          </span>
        </p>
        <p class="mt-2 text-xs">
          Week starting {{ formatDateWithoutTime(stats.thisWeekStart) }}
        </p>
        <p class="mt-1 text-xs">
          {{ stats.thisWeekEvents }} events in
          {{ stats.daysSoFarThisWeek }} days
        </p>
      </UCard>

      <UCard v-if="investmentStats">
        <template #header>
          <h3 class="text-base font-semibold leading-6">
            This Week's Investments (Sat-Fri)
          </h3>
        </template>
        <div class="mt-4 space-y-6">
          <div
            v-for="leadStats in [
              bernieStats,
              dexterStats,
              theoStats,
              marcStats,
            ]"
            :key="leadStats.lead_name"
            class="space-y-2"
          >
            <div class="flex items-baseline justify-between">
              <p class="text-sm font-medium">
                {{ leadStats.lead_name }}'s progress (${{
                  formatCurrency(leadStats.this_week.total_investment)
                }})
              </p>
              <div class="text-right">
                <p class="text-xs">
                  Last week: ${{
                    formatCurrency(leadStats.last_week.total_investment)
                  }}
                </p>
                <p
                  :class="[
                    'text-xs font-semibold',
                    leadStats.percentage_change > 0
                      ? 'text-green-600'
                      : leadStats.percentage_change < 0
                        ? 'text-red-600'
                        : 'text-gray-600',
                  ]"
                >
                  {{ formatPercentageChange(leadStats.percentage_change) }}
                </p>
              </div>
            </div>
            <UProgress
              :value="leadStats.this_week.total_investment"
              :min="0"
              :max="1000000"
              :color="getProgressColor(leadStats.this_week.total_investment)"
              class="mt-2"
            >
              <template #default="{ value, max }">
                ${{ formatCurrency(value) }} / ${{ formatCurrency(max) }}
              </template>
              <template #indicator="{ percent }">
                <div
                  class="text-right"
                  :style="{ width: `${percent}%` }"
                >
                  <span v-if="leadStats.this_week.total_investment === 0">
                  </span>
                  <span
                    v-else-if="leadStats.this_week.total_investment <= 25000"
                  >
                    🥳
                  </span>
                  <span
                    v-else-if="leadStats.this_week.total_investment <= 50000"
                  >
                    🤑
                  </span>
                  <span
                    v-else-if="leadStats.this_week.total_investment <= 100000"
                  >
                    💰
                  </span>
                  <span
                    v-else-if="leadStats.this_week.total_investment <= 200000"
                  >
                    🔥
                  </span>
                  <span
                    v-else-if="leadStats.this_week.total_investment <= 500000"
                  >
                    🚀
                  </span>
                  <span
                    v-else-if="leadStats.this_week.total_investment <= 750000"
                  >
                    ⭐️
                  </span>
                  <span
                    v-else-if="leadStats.this_week.total_investment <= 900000"
                  >
                    💸
                  </span>
                  <span v-else> 🤯 💎 🤩 </span>
                </div>
              </template>
            </UProgress>
          </div>
        </div>
      </UCard>
    </div>
    <div
      v-else
      class="p-4"
    >
      No stats available.
    </div>
  </UDashboardSlideover>
</template>

<script setup>
import { useDashboard } from "@/composables/useDashboard";

const getProgressColor = (amount) => {
  switch (true) {
    case amount === 0:
      return "gray";
    case amount <= 25000:
      return "blue";
    case amount <= 50000:
      return "emerald";
    case amount <= 100000:
      return "amber";
    case amount <= 200000:
      return "orange";
    case amount <= 500000:
      return "red";
    default:
      return "yellow";
  }
};

const { user } = useSanctumAuth();
const { isStatsCardSlideoverOpen } = useDashboard();

const loading = ref(false);
const error = ref(null);
const stats = ref(null);
const investmentStats = ref(null);

const fetchStats = async () => {
  loading.value = true;
  error.value = null;
  try {
    const [outreachStats, investmentStatsData] = await Promise.all([
      useApiFetch("outreach-events/stats", {
        params: {
          user_id: user.value.id,
        },
      }),
      useApiFetch("analytics/lead-investment-stats", {
        params: {
          start_date: getStartOfWeek(),
          end_date: getEndOfWeek(),
        },
      }),
    ]);

    stats.value = outreachStats;
    investmentStats.value = investmentStatsData.data;
  } catch (err) {
    error.value = err.message;
  } finally {
    loading.value = false;
  }
};

const getStartOfWeek = () => {
  const date = new Date();
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(date.setDate(diff)).toISOString().split("T")[0];
};

const getEndOfWeek = () => {
  const date = new Date();
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? 0 : 7);
  return new Date(date.setDate(diff)).toISOString().split("T")[0];
};

const formatPercentageChange = (value) => {
  if (value === null) {
    return "N/A";
  }
  return `${value > 0 ? "+" : ""}${value.toFixed(2)}%`;
};

const bernieStats = computed(
  () =>
    investmentStats.value?.find((stat) => stat.lead_id === 4) || {
      lead_name: "Bernie",
      this_week: { total_investment: 0, investor_count: 0 },
      last_week: { total_investment: 0, investor_count: 0 },
      percentage_change: 0,
    },
);

const theoStats = computed(
  () =>
    investmentStats.value?.find((stat) => stat.lead_id === 26507) || {
      lead_name: "Theo",
      this_week: { total_investment: 0, investor_count: 0 },
      last_week: { total_investment: 0, investor_count: 0 },
      percentage_change: 0,
    },
);

const marcStats = computed(
  () =>
    investmentStats.value?.find((stat) => stat.lead_id === 26244) || {
      lead_name: "Marc",
      this_week: { total_investment: 0, investor_count: 0 },
      last_week: { total_investment: 0, investor_count: 0 },
      percentage_change: 0,
    },
);

const dexterStats = computed(() => {
  const dexterLeadIds = [3, 2096, 6874];
  const dexterStats =
    investmentStats.value?.filter((stat) =>
      dexterLeadIds.includes(stat.lead_id),
    ) || [];

  return dexterStats.reduce(
    (acc, stat) => {
      acc.lead_name = "Dexter";
      acc.this_week.total_investment += stat.this_week.total_investment;
      acc.this_week.investor_count += stat.this_week.investor_count;
      acc.last_week.total_investment += stat.last_week.total_investment;
      acc.last_week.investor_count += stat.last_week.investor_count;
      acc.percentage_change += stat.percentage_change;
      return acc;
    },
    {
      this_week: { total_investment: 0, investor_count: 0 },
      last_week: { total_investment: 0, investor_count: 0 },
      percentage_change: 0,
    },
  );
});

// thought about adding a indicator for the lead with the most raised. Maybe like a fire emoji or something
// const isLeadingLead = computed(() => {
//   const bernieValue = bernieStats.value.this_week.total_investment;
//   const dexterValue = dexterStats.value.this_week.total_investment;
//   return (leadStats) => {
//     if (bernieValue === dexterValue) return false;
//     return (
//       (leadStats.lead_name === "Bernie" && bernieValue > dexterValue) ||
//       (leadStats.lead_name === "Dexter" && dexterValue > bernieValue)
//     );
//   };
// });

watch(isStatsCardSlideoverOpen, (newValue) => {
  if (newValue) {
    fetchStats();
  }
});
</script>
