<template>
  <UDashboardSlideover
    v-model="isNotificationsSlideoverOpen"
    title="Notifications"
  >
    <BaseLoader v-if="loading">Loading notifications...</BaseLoader>

    <div
      v-if="!notificationsList?.length"
      class="p-4"
    >
      <p>No notifications yet, get some work done!</p>
    </div>

    <div
      v-else
      class="divide-y"
    >
      <div
        v-for="notification in notificationsList"
        :key="notification.id"
        class="relative flex cursor-pointer items-center gap-3 p-2"
        @click="markAsRead(notification)"
      >
        <div v-if="!notification.read_at">
          <UChip
            color="red"
            inset
          >
            <UAvatar
              :alt="getNotificationSenderName(notification)"
              size="md"
              class="flex-none"
            />
          </UChip>
        </div>

        <div v-else>
          <UAvatar
            :alt="getNotificationSenderName(notification)"
            size="md"
            class="flex-none"
          />
        </div>

        <div class="flex-1 text-sm">
          <p class="flex items-center justify-end">
            <time
              :datetime="notification.data.created_at"
              class="text-xs"
              v-text="formatTimeAgo(new Date(notification.created_at))"
            />
          </p>
          <p
            class="cursor-pointer hover:text-blue-600"
            @click="handleLinkClick(notification)"
          >
            {{ getNotificationMessage(notification) }}
          </p>
        </div>
      </div>
    </div>
  </UDashboardSlideover>
</template>

<script setup>
import { useDashboard } from "@/composables/useDashboard";
import { formatTimeAgo } from "@vueuse/core";

const { isNotificationsSlideoverOpen } = useDashboard();
const router = useRouter();
const toast = useToast();

const {
  data: notifications,
  status,
  refresh,
} = await useAsyncData("notifications", async () => {
  const response = await useApiFetch("notifications");
  return response;
});

const notificationsList = computed(() => notifications.value?.data || []);
const loading = computed(() => status.value !== "success");

const getNotificationSenderName = (notification) => {
  if (notification.type.includes("DealInvestmentCreated")) {
    return (
      notification.data.deal_investment?.investor?.name || "New Investment"
    );
  }
  return notification.data.sender?.name || "System";
};

const getNotificationMessage = (notification) => {
  if (notification.type.includes("DealInvestmentCreated")) {
    const investment = notification.data.deal_investment;
    return `New investment of $${investment.amount.toLocaleString()} created`;
  }

  if (notification.type.includes("ListSharedNotification")) {
    const permission =
      notification.data.permission_type === "edit" ? "edit" : "view";
    return `${notification.data.sender.name} shared a list with you: ${notification.data.tag.title} (${permission} access)`;
  }

  return notification.data.message || notification.data.type;
};

const checkResourceExists = async (notification) => {
  try {
    if (notification.type.includes("TaskDeletedNotification")) {
      return false;
    }

    if (notification.type.includes("ListSharedNotification")) {
      await useApiFetch(`tags/${notification.data.tag.id}`);
      return true;
    }

    if (notification.type.includes("DealInvestmentCreated")) {
      await useApiFetch(
        `deals/${notification.data.deal_investment.deal_id}/investments/${notification.data.deal_investment.id}`,
      );
      return true;
    }

    if (notification.type.includes("CompanyDeletedNotification")) {
      return false;
    }

    if (
      notification.type.includes("CompanyInactiveNotification") ||
      notification.type.includes("CompanyUpdatedNotification")
    ) {
      await useApiFetch(`companies/${notification.data.company.id}`);
      return true;
    }

    if (notification.data?.task?.id) {
      await useApiFetch(`tasks/${notification.data.task.id}`);
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

const markAsRead = async (notification, suppressToast = false) => {
  if (!notification.read_at) {
    try {
      const response = await useApiPost(
        `notifications/${notification.id}/read`,
      );

      if (response.success && response.notification) {
        const updatedNotification = response.notification;

        if (notifications.value?.data) {
          notifications.value = {
            ...notifications.value,
            data: notifications.value.data.map((n) =>
              n.id === updatedNotification.id ? updatedNotification : n,
            ),
            unread_count: (notifications.value.unread_count || 0) - 1,
          };
        }

        if (!suppressToast) {
          toast.add({
            title: "Notification marked as read",
            color: "green",
          });
        }
      } else {
        console.error("API failed to mark notification as read");
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
      toast.add({
        title: "Error marking notification as read",
        color: "red",
      });
    }
  }
};

const handleLinkClick = async (notification) => {
  await markAsRead(notification, true);

  try {
    const resourceExists = await checkResourceExists(notification);

    if (!resourceExists) {
      toast.add({ title: "This resource has been deleted", color: "yellow" });
      return;
    }

    if (notification.type.includes("CompanyUpdatedNotification")) {
      router.push(`/companies/${notification.data.company.id}`);
    } else if (notification.type.includes("ListSharedNotification")) {
      router.push(`/prospects/lists/${notification.data.tag.id}`);
    } else if (notification.type.includes("DealInvestmentCreated")) {
      router.push(`/deals/${notification.data.deal_investment.deal_id}`);
    } else if (notification.data?.task?.id) {
      router.push(`/tasks/${notification.data.task.id}`);
    }

    if (
      notification.type.includes("CompanyInactiveNotification") ||
      notification.type.includes("CompanyUpdatedNotification")
    ) {
      router.push(`/companies/${notification.data.company.id}`);
    }
  } catch (error) {
    toast.add({ title: "Error checking resource status", color: "red" });
  }
};

const eventListeners = ref([]);

onMounted(() => {
  const user = useSanctumAuth().user.value;
  if (!window.$echo || !user?.client_id) return;

  const channel = window.$echo.private(
    `clients.${user.client_id}.notifications`,
  );

  const events = [
    "task.assigned",
    "task.updated",
    "task.completed",
    "task.reopened",
    "task.deleted",
    "investment.created",
    "investment.updated",
    "investment.deleted",
  ];

  events.forEach((event) => {
    const listener = () => refresh();
    channel.listen(`.${event}`, listener);
    eventListeners.value.push({ event: `.${event}`, listener });
  });
});

onUnmounted(() => {
  if (window.$echo && useSanctumAuth().user.value?.client_id) {
    window.$echo.leave(
      `clients.${useSanctumAuth().user.value.client_id}.notifications`,
    );
  }
});
</script>
